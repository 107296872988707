import request from '@/http/request'
import wayCode from "@/utils/wayCode";
import channelUserIdUtil from "@/utils/channelUserId";
import config from '@/config'

/** 获取url **/
export function getRedirectUrl() {
    return request.request({
        url: '/api/cashier/redirectUrl',
        method: 'POST',
        data: {wayCode: wayCode.getPayWay().wayCode, token: config.cacheToken}
    })
}

/** 获取url **/
export function getChannelUserId(redirectData) {
    return request.request({
        url: '/api/cashier/channelUserId',
        method: 'POST',
        data: Object.assign({wayCode: wayCode.getPayWay().wayCode, token: config.cacheToken}, redirectData)
    })
}

/** 调起支付接口, 获取支付数据包 **/
export function getPayPackage(amount) {
    return request.request({
        url: '/api/cashier/pay',
        method: 'POST',
        data: {
            wayCode: wayCode.getPayWay().wayCode,
            token: config.cacheToken,
            amount: amount,
            channelUserId: channelUserIdUtil.getChannelUserId()
        }
    })
}

/** 调起支付接口, 获取订单信息 **/
export function getPayOrderInfo() {
    return request.request({
        url: '/api/cashier/payOrderInfo',
        method: 'POST',
        data: {
            token: config.cacheToken
        }
    })
}

/** 取消订单 **/
export function cancelPay() {
    return request.request({
        url: '/api/cashier/cancelPay',
        method: 'POST',
        data: {
            token: config.cacheToken
        }
    })
}

/** 查询个卡订单 **/
export function queryACardOrder(payOrderId) {
    return request.request({
        url: '/api/queryPayOrders/' + payOrderId,
        method: 'GET',
    })
}
/** 查询个卡订单结果信息 **/
export function queryPayOrdersResultInfo(payOrderId) {
    return request.request({
        url: '/api/queryPayOrdersState/' + payOrderId,
        method: 'GET',
    })
}

/** 提交用户付款信息 **/
export function pushACardOrder(formData) {
    return request.request({
        url: '/api/pushACardOrder',
        method: 'POST',
        data: formData
    })
}

/** 查询这个订单的虚拟收钱账号信息**/
export function queryH5Order(payOrderId) {
    return request.request({
        url: '/api/queryH5Order/' + payOrderId,
        method: 'GET',
    })
}


/** 获取这个商户下面开通了哪些 h5 插件支付 **/
export function queryH5PluginOrders(payOrderId) {
    return request.request({
        url: '/api/queryH5PluginOrders/' + payOrderId,
        method: 'GET',
    })
}
